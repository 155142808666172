<template>
  <dashboard-box>
    <template v-slot:preview>
      <div class="card card-custom">
        <div class="card-body relative">
          <div class="row">
            <div class="col-xl-12" v-if="settings">
              <h3 v-b-toggle="'general'" class="cursor-pointer pb-2 mb-5 d-flex justify-content-center">
                <div class="text-underline-gray">
              <span v-html="getIconByKey('icons.sample.system-description', {})"
              ></span>
                  <span>{{ $t('general_settings.general') }}</span>
                  <i class="fas fa-caret-down ml-3"></i>
                </div>
              </h3>
              <b-collapse visible id="general" class="row bg-gray-100 border-15px pt-5 py-0 mb-5">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <b-form-group>
                      <button-with-icon
                          @onClick="clearCache"
                          class="font-weight-boldest"
                          :icon-name="null"
                          :text="$t('general.clear_cache').toUpperCase()"
                          size="xl"
                      ></button-with-icon>
                    </b-form-group>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <b-form-group>
                      <button-with-icon
                          @onClick="resetInvalidInvoices"
                          class="font-weight-boldest"
                          :icon-name="null"
                          :text="$t('general.reset_invalid_invoices').toUpperCase()"
                          size="xl"
                      ></button-with-icon>
                    </b-form-group>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <b-form-group>
                      <number-input
                          name="login_duration"
                          :model.sync="settings.general.signed_url_validate_duration"
                          :title="`${$t('general_settings.signed_url_validation_duration')}`"
                      ></number-input>
                    </b-form-group>
                </div>
              </b-collapse>

              <h3 v-b-toggle="'esc_configurations'" class="cursor-pointer pb-2 mb-5 d-flex justify-content-center">
                <div class="text-underline-gray">
              <span v-html="getIconByKey('icons.sample.system-description', {})"
              ></span>
                  <span>{{ $t('general_settings.esc_configurations') }}</span>
                  <i class="fas fa-caret-down ml-3"></i>
                </div>
              </h3>
              <b-collapse visible id="esc_configurations" class="row bg-gray-100 border-15px pt-5 py-0 mb-5">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <b-form-group>
                    <number-input
                        name="login_duration"
                        :model.sync="settings.esc_configurations.wastage"
                        :title="`${$t('general_settings.wastage')}`"
                    ></number-input>
                  </b-form-group>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <b-form-group>
                    <number-input
                        name="login_duration"
                        :model.sync="settings.esc_configurations.dividend"
                        :title="`${$t('general_settings.dividend')}`"
                    ></number-input>
                  </b-form-group>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <b-form-group>
                    <number-input
                        name="login_duration"
                        :model.sync="settings.esc_configurations.exchange_difference"
                        :title="`${$t('general_settings.exchange_difference')}`"
                    ></number-input>
                  </b-form-group>
                </div>
              </b-collapse>

              <h3 v-b-toggle="'fabric_request'" class="cursor-pointer pb-2 mb-5 d-flex justify-content-center">
                <div class="text-underline-gray">
              <span v-html="getIconByKey('icons.sample.system-description', {})"
              ></span>
                  <span>{{ $t('general_settings.fabric_request') }}</span>
                  <i class="fas fa-caret-down ml-3"></i>
                </div>
              </h3>
              <b-collapse visible id="fabric_request" class="row bg-gray-100 border-15px pt-5 py-0 mb-5">
                <div class="col-12">
                  <p class="text-lg text-primary font-weight-bold"><span class="bottom-border-1px-primary">{{ $t('general_settings.expense_request').toUpperCase() }}</span></p>
                </div>
                <div class="col-12 row">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <b-form-group>
                      <number-input
                          name="login_duration"
                          :model.sync="settings.fabric_request.expense_coefficient"
                          :title="`${$t('general_settings.expense_coefficient')}`"
                      ></number-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="col-12">
                  <p class="text-lg text-primary font-weight-bold"><span class="bottom-border-1px-primary">{{ $t('general_settings.accessory_request').toUpperCase() }}</span></p>
                </div>

                <div class="col-12 row">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <b-form-group>
                      <number-input
                          name="login_duration"
                          :model.sync="settings.fabric_request.accessory_consumption_coefficient"
                          :title="`${$t('general_settings.accessory_coefficient')}`"
                      ></number-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="col-12 row">
                  <div class="col-12">
                    <p class="text-lg text-primary font-weight-bold"><span class="bottom-border-1px-primary">{{ $t('general_settings.paint_coefficient').toUpperCase() }}</span></p>
                  </div>
                  <div class="col-12 row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <b-form-group>
                        <number-input
                            name="login_duration"
                            :model.sync="settings.fabric_request.paint_coefficient"
                            :title="`${$t('general_settings.first_coefficient')}`"
                        ></number-input>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="col-12">
                    <p class="text-lg text-primary font-weight-bold"><span class="bottom-border-1px-primary">{{ $t('general_settings.knit_coefficient').toUpperCase() }}</span></p>
                  </div>
                  <div class="col-12 row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <b-form-group>
                        <number-input
                            name="login_duration"
                            :model.sync="settings.fabric_request.knit_coefficient"
                            :title="`${$t('general_settings.first_coefficient')}`"
                        ></number-input>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="col-12">
                    <p class="text-lg text-primary font-weight-bold"><span class="bottom-border-1px-primary">{{ $t('general_settings.ready_fabric_coefficient').toUpperCase() }}</span></p>
                  </div>
                  <div class="col-12 row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <b-form-group>
                        <number-input
                            name="login_duration"
                            :model.sync="settings.fabric_request.ready_fabric_coefficient"
                            :title="`${$t('general_settings.first_coefficient')}`"
                        ></number-input>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="col-12">
                    <p class="text-lg text-primary font-weight-bold"><span class="bottom-border-1px-primary">{{ $t('general_settings.print_pigment_coefficient').toUpperCase() }}</span></p>
                  </div>
                  <div class="col-12 row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <b-form-group>
                        <number-input
                            name="login_duration"
                            :model.sync="settings.fabric_request.print_pigment_coefficient"
                            :title="`${$t('general_settings.first_coefficient')}`"
                        ></number-input>
                      </b-form-group>
                    </div>
                  </div>


                  <div class="col-12">
                    <p class="text-lg text-primary font-weight-bold"><span class="bottom-border-1px-primary">{{ $t('general_settings.print_reagent_coefficient').toUpperCase() }}</span></p>
                  </div>
                  <div class="col-12 row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <b-form-group>
                        <number-input
                            name="login_duration"
                            :model.sync="settings.fabric_request.print_reagent_coefficient"
                            :title="`${$t('general_settings.first_coefficient')}`"
                        ></number-input>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="col-12">
                    <p class="text-lg text-primary font-weight-bold"><span class="bottom-border-1px-primary">{{ $t('general_settings.print_disperse_coefficient').toUpperCase() }}</span></p>
                  </div>
                  <div class="col-12 row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <b-form-group>
                        <number-input
                            name="login_duration"
                            :model.sync="settings.fabric_request.print_disperse_coefficient"
                            :title="`${$t('general_settings.first_coefficient')}`"
                        ></number-input>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="col-12">
                    <p class="text-lg text-primary font-weight-bold"><span class="bottom-border-1px-primary">{{ $t('general_settings.print_devore_coefficient').toUpperCase() }}</span></p>
                  </div>
                  <div class="col-12 row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <b-form-group>
                        <number-input
                            name="login_duration"
                            :model.sync="settings.fabric_request.print_devore_coefficient"
                            :title="`${$t('general_settings.first_coefficient')}`"
                        ></number-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-12">
                    <p class="text-lg text-primary font-weight-bold"><span class="bottom-border-1px-primary">{{ $t('general_settings.additional_processing_coefficient').toUpperCase() }}</span></p>
                  </div>
                  <div class="col-12 row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <b-form-group>
                        <number-input
                            name="login_duration"
                            :model.sync="settings.fabric_request.additional_processing_coefficient"
                            :title="`${$t('general_settings.first_coefficient')}`"
                        ></number-input>
                      </b-form-group>
                    </div>
                  </div>

                </div>
              </b-collapse>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button-with-icon
              @onClick="submitSettings"
              class="font-weight-boldest"
              :icon-name="null"
              :text="$t('general.save').toUpperCase()"
              size="xl"
              :disabled="!isValidToSubmit"
            ></button-with-icon>

          </div>
        </div>

      </div>

    </template>
  </dashboard-box>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import TextInput from "@/assets/components/inputs/TextInput";
import {GET_ITEMS as REST_GET_SETTINGS} from "@/core/services/store/REST.module";
import {UPDATE_ITEM_BY_ID, GET_ITEM_DETAIL_BY_ID} from "@/core/services/store/panel-managment/crud/panel.management.module";
import NumberInput from "@/assets/components/inputs/NumberInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";

export default {
  name: "GeneralSettingIndex",
  components: {
    ButtonWithIcon,
    NumberInput,
    TextInput,
    DashboardBox
  },
  computed: {
    isValidToSubmit(){
      function checkRecursively(data){
        if(data === Object(data) || Array.isArray(data)){
          let result = 1;
          for (let i in data) {
            result *= checkRecursively(data[i]);
          }
          return result;
        }else {
          if(data === '' || data === null) {
            return false;
          }else return true;
        }
      }
      if(!this.settings) return false;
      return checkRecursively(this.settings);
    }
  },
  data() {
    return {
      settings: null,
    };
  },
  methods: {
    submitSettings(){
      let payload = {
        url: 'api/general-settings/1',
        id: 1,
        contents: {
          general :this.settings.general,
          fabric_request : this.settings.fabric_request,
          esc_configurations : this.settings.esc_configurations,
        },
      }, self = this;

      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then(result => {
        if(result.status) {
          self.sweetAlertSuccess('general.successfully_updated')
        }
      })
    },
    clearCache(){
      this.sweetAlertLoading();
      let payload = {
        url: 'api/general-setting/clear-cache',
      }, self = this;

      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload).then(result => {
        self.sweetAlertClose();
        self.sweetAlertSuccess('general.success')
      })
    },
    resetInvalidInvoices(){
      this.sweetAlertLoading();
      let payload = {
        url: 'api/invoice/reset/invoice-errors',
      }, self = this;

      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload).then(result => {
        setTimeout(() => {
          self.sweetAlertClose();
          self.sweetAlertSuccess('general.success')
        }, 1000);
      })
    },
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t('menu.management.general_settings') }
    ]);

    this.$store.dispatch(REST_GET_SETTINGS,{url : "api/general-settings"}).then((result) => {
      self.settings = {
        general : result.data[0].general,
        fabric_request : result.data[0].fabric_request,
        esc_configurations : result.data[0].esc_configurations
      };
    });
  }
}
</script>

<style scoped>
*:focus {
  outline: none;
}
</style>
